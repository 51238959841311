import React from "react"
import Layout from "../components/Layout"
import ExpenseManagement from "../components/Pages/ExpenseManagement"

export default function HomeDefault(props) {
  return (
    <Layout>
      <ExpenseManagement />
    </Layout>
  )
}
